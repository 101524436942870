

import jwtDecode from 'jwt-decode';
  
  export const checkToken = () => { 
    try{
        const tokenCheck = localStorage.getItem('token');
        localStorage.setItem("currentUserId",jwtDecode(tokenCheck)._id)
        return tokenCheck !== null;
    }catch(e){
        return false
    }
  };  