import { gql } from '@apollo/client'


export const USER_PROFILE = gql`
    query UserProfile($userId:String!){
        userProfile(userId:$userId){
            costumer{
                _id
                username
                company
                email 
                } 
            licences{
                _id
                expirationDate
                productionCode
                appVersion
                maximumNumberOfMachines 
                featuresF1

                }
            machines{
                _id
                platform
                hostname
                ipAddressOut
                isBlocked 
                } 
        }
    }
`


export const COSTUMER_LICENCES = gql`
    query getCostumerLicences($cosrumerId:String!){
        userProfile(cosrumerId:$cosrumerId){
            _id
            expirationDate
            productionCode
            appVersion
            maximumNumberOfMachines
        }
    }
`


  