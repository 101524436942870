import React, { useState } from 'react';
import { Row, Col, Button, Form, ListGroup } from 'react-bootstrap';
import { EDIT_LICENCE, REMOVE_MACHINE } from '../../../Graphql/Mutations/AdminUser';
import { useMutation, useQuery } from '@apollo/client';
import { GET_EDIT_LICENCE } from '../../../Graphql/Queries/Licence';
import { useNavigate } from "react-router-dom";
import { formatTarih } from '../../../Helper/TimeHelper';


const LicenceEdit = () => {
    const navigate = useNavigate();

    const [productionCode, setProductionCode] = useState("")
    const [licenceId, setLicenceId] = useState("")
    const [appVersion, setappVersion] = useState('')
    const [maximumNumberOfMachines, setmaximumNumberOfMachines] = useState(0)
    const [expirationDate, setExpirationDate] = useState(new Date())
    const [featuresF1, setFeaturesF1] = useState(true)
    const [featuresF2, setFeaturesF2] = useState(true)
    const [featuresF3, setFeaturesF3] = useState(true)
    const [featuresF4, setFeaturesF4] = useState(true)
    const [featuresF5, setFeaturesF5] = useState(true)
    const [notes, setNotes] = useState("")

    const setOncomplateDataQuery = () => {
        setProductionCode(data.getEditLicence.productionCode)
        setappVersion(data.getEditLicence.appVersion)
        setmaximumNumberOfMachines(parseInt(data.getEditLicence.maximumNumberOfMachines))
        setExpirationDate(new Date(parseInt(data.getEditLicence.expirationDate)))

        setLicenceId(data.getEditLicence._id)
        setFeaturesF1(data.getEditLicence.featuresF1)
        setFeaturesF2(data.getEditLicence.featuresF2)
        setFeaturesF3(data.getEditLicence.featuresF3)
        setFeaturesF4(data.getEditLicence.featuresF4)
        setFeaturesF5(data.getEditLicence.featuresF5)
        setNotes(data.getEditLicence.notes)

    }

    const currentLicenceId = localStorage.getItem("currentLicenceId")
    const { data, refetch } = useQuery(GET_EDIT_LICENCE, { variables: { licenceId: currentLicenceId }, onCompleted: setOncomplateDataQuery });
    const [EditLicence, { loading }] = useMutation(EDIT_LICENCE)
    const [RemoveMachine ] = useMutation(REMOVE_MACHINE)


    const editLicenceData = () => {
        let expDate = (new Date(expirationDate).valueOf().toString())
        if (currentLicenceId === undefined && !productionCode && !appVersion && maximumNumberOfMachines < 1) {
            alert("Form değerlri hatalı")
            return
        } else if (expirationDate <= new Date()) {
            alert("Son kullanma tarihi bugünün tarihinden en az bir gün ileride olmalı!")
            return
        }
        let variables = { licenceId: currentLicenceId, productionCode, appVersion, maximumNumberOfMachines, expirationDate: expDate, featuresF1, featuresF2, featuresF3, featuresF4, featuresF5, notes }
        EditLicence({
            variables,
            onCompleted: ({ editLicence }) => {
                alert(editLicence.message)
                refetch()
                navigate('/admin')
            },
            onError: (error) => {
                alert(`Bir hata alındı! ${error}`)
            }
        })
    }

 
    if (!data) {
        return (
            <div>
                HATA
            </div>
        )
    } 
    const { getEditLicence } = data
    const handleDeleteMachine = (macAddress) => {
        if (window.confirm(`${macAddress} isimli makineyi silmek istediğinizden emin misiniz?`)) {
            RemoveMachine({
                variables: { licenceId: currentLicenceId, macAddress },
                onCompleted: ({ removeMachine }) => {
                    alert(`${macAddress} isimli makine mevcut lisanstan başarıyla silindi!`); // Başarılı bildirimi
                    refetch();
                },
                onError: (error) => {
                    alert(`Silme işlemi sırasında bir hata oluştu: ${error.message}`); // Hata bildirimi
                },
            });
        }
    };
    return (
        <Row className="vh-100 d-flex justify-content-center" style={{ marginTop: '10%' }}>
            <Col md={8} lg={10} xs={12}>
                <h4 style={{ backgroundColor: '#ffc107', borderRadius: 5, padding: 10, alignItems: 'center', alignContent: 'center' }}>Lisans Düzenle</h4>
                <Form>
                    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}>Müşteri Email</Form.Label>
                            <Form.Control type="text" disabled placeholder={getEditLicence.costumer.email} value={getEditLicence.costumer.email} />
                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}>Müşteri Kullanıcı Adı</Form.Label>
                            <Form.Control type="text" disabled value={getEditLicence.costumer.username} />

                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}>Müşteri Şirket Adı</Form.Label>
                            <Form.Control type="text" disabled value={getEditLicence.costumer.companyName} />
                        </Col>
                    </Row>


                    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans Adı</Form.Label>
                            <Form.Control type="text" placeholder='Etex' value={productionCode} onChange={(event) => setProductionCode(event.target.value)} />
                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans Versiyon</Form.Label>
                            <Form.Control type="text" placeholder='3.5.1' value={appVersion} onChange={(event) => setappVersion(event.target.value)} />
                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans Adet</Form.Label>
                            <Form.Control type="number" placeholder='1' value={maximumNumberOfMachines} onChange={(event) => setmaximumNumberOfMachines(parseInt(event.target.value))} />
                        </Col>
                    </Row>

                    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans ID</Form.Label>
                            <Form.Control type="text" disabled value={licenceId} />
                        </Col>
                        <Col md={3} lg={3} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Son Kullanma Tarihi</Form.Label>
                            <Form.Control type="date" name="datepic" placeholder="DateRange" onChange={(e) => { setExpirationDate(new Date(e.target.value)); }} value={formatTarih(expirationDate.valueOf())} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F1(Demo)</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF1} onChange={(event) => setFeaturesF1(event.target.checked)} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F2</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF2} onChange={(event) => setFeaturesF2(event.target.checked)} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F3</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF3} onChange={(event) => setFeaturesF3(event.target.checked)} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F4</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF4} onChange={(event) => setFeaturesF4(event.target.checked)} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F5</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF5} onChange={(event) => setFeaturesF5(event.target.checked)} />
                        </Col>
                        <Col md={12} lg={12} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans Notları</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder='Lisans notlari' value={notes} onChange={(event) => setNotes(event.target.value)} />
                        </Col>
                    </Row>

                    <Col style={{ marginTop: '5%' }} md={12} lg={12} xs={12}>
                        <h3 style={{ backgroundColor: '#ffffff', borderRadius: 5, padding: 10 }}>Makine Listesi</h3>
                        <ListGroup>
                            {getEditLicence.machines.map((machine) => (
                                <ListGroup.Item key={machine.macAddress}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: "5px" }}>
                                        <span>{machine._id ? machine._id : "-"}</span>
                                        <span>{machine.ipAddress ? machine.ipAddress : "-"}</span>
                                        <span>{machine.ipAddressOut ? machine.ipAddressOut : "-"}</span>
                                        <span>{machine.macAddress ? machine.macAddress : "-"}</span>
                                        <span>{machine.platform ? machine.platform : "-"}</span>
                                        <span>{machine.hostname ? machine.hostname : "-"}</span>
                                        <span>{machine.architecture ? machine.architecture : '-'}</span>
                                        <span>{machine.processor ? machine.processor : '-'}</span>
                                        <span>{machine.ram ? machine.ram : ''}</span>
                                        <div>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={!machine.isBlocked}
                                                />
                                            </Form>
                                        </div>
                                        <Button variant="danger" onClick={() => handleDeleteMachine(machine.macAddress)}>Sil</Button>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>

                    <Button variant="warning" style={{ marginTop: '16px' }} onClick={editLicenceData} disabled={loading}>
                        LİSANSI KAYDET
                    </Button>
                </Form>
            </Col>
        </Row>

    );
};


export default LicenceEdit;