import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
} from "react-icons/di";
import { SiCsharp } from "react-icons/si";
import {
  SiPytorch,
  SiFirebase,
} from "react-icons";

import etabs from '../../Assets/Icons/etabs.png'
import sap2000 from '../../Assets/Icons/sap2000.png'
import safe from '../../Assets/Icons/safe.png'
import revit from '../../Assets/Icons/revit.png'
import navisworks from '../../Assets/Icons/navisworks.png'

 
 
 

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <img src={etabs} style={{ width: '120px',   }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={sap2000} style={{ width: '120px',   }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={safe} style={{ width: '120px',   }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={revit} style={{ width: '120px',   }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <img src={navisworks} style={{ width: '120px',   }} />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCsharp />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
    
      
      
    </Row>
  );
}

export default Techstack;
