import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap"; 
import { AiOutlineCheckCircle } from 'react-icons/ai'
 

function Etex() {
  return (
    <section>
      
      <Container fluid className="home-sectison" id="homes">
        <Container className="home-content">
          <Row>
            <Col md={7}>
              <h1 style={{ paddingBottom: 15 }} >
                ETEX{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻 Etabs - Excel Araçları
                </span>
              </h1> 
              <iframe width="560" height="315" title="Etex" src="https://www.youtube.com/embed/mJNyxbTtVLE" frameBorder="0" allowFullScreen></iframe>

            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <p>
                Etabs API kullanarak <span className="purple">TBDY2018</span> ve <span className="purple">TS500</span> yönetmeliklerine uygun çeşitli kontroller ve raporlamalar yapan bir yazılım.
                <br /><br />Etex betonarme hesaplar için <span className="purple">CSI Etabs </span> yazılımı ve <span className="purple">MS Excel</span> ile entegre çalışan, hesap modellerinde tanımlanması gereken parametreleri otomatize bir şekilde tanımlayabilir.
                <br />Hesap modelinde yapılması gereken kontrolleri Etabs API ve Excel API kullanarak hızlıca yapmaya olanak sağlayan ve bu çıktıları <span className="purple">hesap raporu</span> oluşturabilecek şekilde hem <span className="purple">PDF</span> formatında hem Excel formatında kullanıcılara çıktı üreten bir ara yazılımdır.
                <br /><br /><span className="purple">Formatlanmış Excel</span> dosyaları kullanıcıların çeşitli parametreleri dinamik bir şekilde değiştirebilmelerine olanak tanır.
                <br />Bu şekilde farklı yönetmeliklere ait değerleri test edebilmeyi sağlar. Excel dosyaları <span className="purple">istenilen formatta</span> düzenlenebilir.
              </p>
              <br />
            <Button href="/costumer">ETEX İNDİR</Button>
            </Col>
            <Col md={12} style={{ paddingBottom: 20 }}>
              <h3>ETEX'TE BULUNAN ARAÇLAR</h3>
                <a className='modules-item' href='#01' style={{textDecoration: 'none', color:'#ffffff'}}><AiOutlineCheckCircle className='service__list-icon'/>01 - Deprem Hesabı Aracı | Eşdeğer Birleştirme Yöntemi</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#02' style={{textDecoration: 'none', color:'#ffffff'}} >02 - Deprem Hesabı Aracı | Modal Birleştirme Yöntemi</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#03' style={{textDecoration: 'none', color:'#ffffff'}} >03 - Kontrol | Kolon Eksenel Kapasite Kontrolü</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#04' style={{textDecoration: 'none', color:'#ffffff'}} >04 - Kontrol | Perde Eksenel Kapasite Kontrolü</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#05' style={{textDecoration: 'none', color:'#ffffff'}} >05 - Kontrol | Perde Kesme Kapasite Kontrolü | Donatılandırma</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#06' style={{textDecoration: 'none', color:'#ffffff'}} >06 - Kontrol | Göreli Kat Öteleme Kontrolü</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#07' style={{textDecoration: 'none', color:'#ffffff'}} >07 - Kontrol | Zımbalama Kontrolü</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#08' style={{textDecoration: 'none', color:'#ffffff'}} >08 - Kontrol | Kolon Etriye Kontrolü</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#09' style={{textDecoration: 'none', color:'#ffffff'}} >09 - Kontrol | Kiriş Etriye Kontrolü</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#10' style={{textDecoration: 'none', color:'#ffffff'}} >10 - Donatı Seçimi | Kiriş Donatı Seçim Aracı</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#11' style={{textDecoration: 'none', color:'#ffffff'}} >11 - Donatı Seçimi | Kolon Donatı Seçim Aracı</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#12' style={{textDecoration: 'none', color:'#ffffff'}} >12 - Modelleme | Frame Plan</a>
                <br/><AiOutlineCheckCircle className='service__list-icon'/><a className='modules-item' href='#13' style={{textDecoration: 'none', color:'#ffffff'}} >13 - Modelleme | Tablalı Kiriş Kesiti Tanımlama Aracı</a>
                <br/>
        </Col>
          </Row>
          <Row>
          <div className="etex__tools">
        <h1>Etex Araçları</h1>
        <EtexTool id="01" title="Deprem Hesabı Aracı - Eşdeğer Yöntem" video_uri="https://www.youtube.com/embed/mJNyxbTtVLE"  properties={["TDBY2018 uyumlu deprem hesabı", "Deprem spektrumu tanımlama ve Etabs'e entegrasyonu", "Yük birleşimlerini otomatik tanımlama ve katsayılarını güncelleyebilme", "Minimum taban kesme kuvveti hesabı", "Maksimum periyot hesabı", "Verileri kaydedip okuyabilme", "Deprem hesabı raporu oluşturabilme",]}/>
        <EtexTool id="02" title="Deprem Hesabı Aracı - Modal Yöntem" video_uri="https://www.youtube.com/embed/mJNyxbTtVLE"   properties={["TDBY2018 uyumlu deprem hesabı", "Deprem spektrumu tanımlama ve Etabs'e entegrasyonu", "Yük birleşimlerini otomatik tanımlama ve katsayılarını güncelleyebilme", "Minimum taban kesme kuvveti hesabı", "Maksimum periyot hesabı", "Verileri kaydedip okuyabilme", "Deprem hesabı raporu oluşturabilme",]}/>
        <EtexTool id="03" title="Kolon Eksenel Kapasite Kontrol Aracı" video_uri="https://www.youtube.com/embed/2TV4eLEKf6w" properties={["Kolon eksenel yük kapasite kontrolü","Dinamik Excel formatında çıktı", "Hızlı ve dinamik veri parametre değişikliği", "Üst yapı ve bodrum için ayrı kombinasyon seçimi", "Hesap raporu oluşturabilme","Özelleştirilebilir Excel formatı kullanabilme"]}/>
        <EtexTool id="04" title="Perde Eksenel Kapasite Kontrol Aracı" video_uri="https://www.youtube.com/embed/0XfHUiibt0g" properties={["Perde eksenel yük kapasite kontrolü","Dinamik Excel formatında çıktı", "Hızlı ve dinamik veri parametre değişikliği", "Üst yapı ve bodrum için ayrı kombinasyon seçimi", "Hesap raporu oluşturabilme","Özelleştirilebilir Excel formatı kullanabilme"]}/>
        <EtexTool id="05" title="Perde Kesme ve Donatılandırma Aracı" video_uri="https://www.youtube.com/embed/lAvtfxSilFI"  properties={["Perde kesme kapasite kontrolü","Dinamik Excel formatında çıktı", "Hızlı ve dinamik veri parametre değişikliği", "Üst yapı ve bodrum için ayrı kombinasyon seçimi", "Hesap raporu oluşturabilme","Özelleştirilebilir Excel formatı kullanabilme","Yatay donatı seçimi ve çiroz seçimi","CAD ortamına verilen donatıların aktarılması"]}/>
        <EtexTool id="06" title="Göreli Kat Ötelemesi Kontrol Aracı" video_uri="https://www.youtube.com/embed/ao76k-IdbHc" properties={["Göreli kat ötelemesi kontrolü","Dinamik Excel formatında çıktı", "Hızlı ve dinamik veri parametre değişikliği", "Hesap raporu oluşturabilme","Özelleştirilebilir Excel formatı kullanabilme"]}/>
        <EtexTool id="07" title="Zımbalama Kontrol Aracı" video_uri="https://www.youtube.com/embed/fPXL663q62s" properties={["Temel ve döşeme zımbalama kontrolü","Dinamik Excel formatında çıktı", "Hızlı ve dinamik veri parametre değişikliği", "Düşey yük ve depremli durum için ayrı kombinasyon seçimi", "Hesap raporu oluşturabilme","Özelleştirilebilir Excel formatı kullanabilme"]}/>
        <EtexTool id="09" title="Kiriş Donatı Seçim Aracı" video_uri="https://www.youtube.com/embed/rHJSdULzvds" properties={["Kiriş donatı seçimi","Autocad'e seçilen donatıların aktarımı","Otomatik aks isimlendirme ve sıralama","Dinamik Excel formatında çıktı", "Hızlı ve dinamik veri parametre değişikliği", "Özelleştirilebilir Excel formatı kullanabilme"]}/>
      </div>
          </Row>
        </Container>

      </Container> 
       
    </section>
  );
}


function EtexTool(props) {

  return <div className="mt-4" style={{ backgroundColor:'#3D3C40', borderRadius: 5, padding: 6, transition: 'transform 0.3s ease-in-out'  }}>
    <h2 className="purple" href={props.id}>{props.id} - {props.title}</h2>
    <section className="tool_content" id={props.id}>
      <Row className="flex-row m-5" >
        <Col md={4} lg={6} xs={12}>
          <iframe width="100%" height="100%" src={props.video_uri} title={props.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Col>
        <Col md={4} lg={6} xs={12} >
          {props.properties.map(item => {
            return <li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'baseline' }}><AiOutlineCheckCircle size={20} className="purple" /><p >{item}</p></li>
          })}        </Col>
      </Row>
    </section>
  </div>
}
export default Etex;
