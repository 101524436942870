import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import { LOGIN } from '../../../Graphql/Mutations/Auth'
import { useMutation } from '@apollo/client' 
import { checkToken } from '../../../services/Auth'
import { useNavigate } from "react-router-dom";
import { isEmailValid } from "../../../Helper/EmailStringChecker.js";


function LoginPage() { 
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
 
  const [login, { loading}] = useMutation(LOGIN);
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isEmailValid(email)) {
      setErrorMessage('Lütfen geçerli bir email giriniz!')
      return
    }
    if (!email || !password) {
      setErrorMessage("E-posta ve parola zorunlu alanlardır.");
      return;
    }

     
    try {
      login({
        variables: { email, password },
        onCompleted: (__data) => {
          const { token } = __data.login;
          localStorage.setItem('token',  `Bearer ${token}`); 
          checkToken()
          navigate('/costumer')
        },
        onError: (e)=>{
          setErrorMessage(e.message)
        }
      });  
    } catch (e) {
      setErrorMessage(e.message);
      // throw new Error(e)
    }
  };
  
  useEffect(() => {
    
    if (checkToken()) {
      navigate('/costumer')
    }
  })
   
  return (
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <div className="border border-3 border-primary"></div>
          <Card className="shadow">
            <Card.Body>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase ">GİRİŞ</h2>
                <div className="mb-3">
                  <Form>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label className="text-center">
                        E-Posta
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="e-posta"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value.toLowerCase().trim())}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="formBasicPassword"
                    >
                      <Form.Label>Parola</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Parola"
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox"
                    >
                      <p className="small">
                        <a className="text-primary" href="/forgotpassword">
                          Şifremi Unuttum?
                        </a>
                      </p>
                    </Form.Group>
                    
                    <div className="d-grid">
                      <Button variant="primary" onClick={handleSubmit} disabled={loading?true:false}>
                        Giriş
                      </Button>
                    </div>
                  </Form>
                  <div className="mt-3">
                    <p className="mb-0  text-center">
                      Hesabım yok?{" "}
                      <a href="register" className="text-primary fw-bold">
                        Kaydol
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default LoginPage;