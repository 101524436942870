import React from 'react';
import { Col, Button, Badge, Table } from 'react-bootstrap';
import { DELETE_LICENCE, DELETE_COSTUMER } from '../../../Graphql/Mutations/AdminUser';
import { useNavigate } from "react-router-dom";
import { useMutation } from '@apollo/client';

import { formatTarih } from '../../../Helper/TimeHelper'
import { BsTrash, BsPencil } from 'react-icons/bs'


const UserListItem = ({ user, adminId, refreshMain }) => {
    const navigate = useNavigate();
    const [DeleteLicence, { loading }] = useMutation(DELETE_LICENCE)

    const deleteLicenceItem = (itemId) => {
        const result = window.confirm('Bu lisans anahtarini silmek istediğizden emin misiniz?');

        if (result) {
            // TODO add loading
            DeleteLicence(
                {
                    variables: { adminId, licenceId: itemId },
                    onCompleted: ({ deleteLicence }) => {
                        alert(deleteLicence.message)
                        refreshMain()
                    },
                    onError: (error => {
                        alert(`Bir hata alindi! ${error}`)
                    })
                })
        }
    }

    const [DeleteCostumer] = useMutation(DELETE_COSTUMER)


    const deleteCostumerItem = (costumerId) => {
        const result = window.confirm('Bu kullanıcıyı linsanları ile birlikte silmek istediğizden emin misiniz?');

        if (result) {
            // TODO add loading
            DeleteCostumer(
                {
                    variables: { adminId: adminId, costumerId },
                    onCompleted: ({ deleteCostumer }) => {
                        alert(deleteCostumer.message)
                        refreshMain()
                    },
                    onError: (error => {
                        alert(`Bir hata alindi! ${error}`)
                    })
                })
        }
    }

    const navigationHandle = async () => {
        localStorage.setItem("currentCostumerId", user._id)
        navigate('/admin/addnewlicence');
    }

    const editLicenceItem = (currentLicenceId) => {
        localStorage.setItem("currentLicenceId", currentLicenceId)
        navigate('/admin/editlicence');

    }

    const removeAllMachines = (currentLicenceId) => {

    }

    const goMachineDetail = (currentLicenceId) => {
        localStorage.setItem("currentLicenceId", currentLicenceId)
        navigate('/admin/machines');

    }
    const badgeStyle = { minWidth: '12%' } 
    return (
        <Col md={12} lg={12} xs={12} style={{ backgroundColor: '#EAEAEA', borderRadius: 5, alignItems: 'center', alignContent: 'center', marginTop: '4px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-around', padding: '8px' }}>
            <Col md={2} xs={12} lg={2} style={{ border: '1px solid purple', borderRadius: '4px', padding: '8px', textAlign: 'center' }}>
  <p style={{ fontWeight: 'bold', fontSize: '1.2rem', margin: '0' }}>{user.email}</p>
  <p style={{ fontWeight: 'bold', fontSize: '1.2rem', margin: '0' }}>{user.username}</p>
  <p>{user.company}</p>
</Col>
            <Col md={6} xs={12} lg={8}>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Yazılım Adı</th>
                            <th>Yazılım Versiyon</th>
                            <th>Lisans Anahtarı</th>
                            <th>Max Makina</th>
                            <th>Lisans Başlangıc T.</th>
                            <th>Lisans Bitiş T.</th>
                            <th>Lisans Tipi</th>
                            <th>Sil</th>
                            <th>Düzenle</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user.licences.map((licence, index) => {
                            const expirationDate = formatTarih(Number(licence.expirationDate));
                            const today = new Date().toISOString().split('T')[0];
                            const badgeClassName = expirationDate < today ? 'danger' : 'success';

                            return (
                                <tr key={index}>
                                    <td><Badge className='m-2' bg="light" text="dark">{index + 1}</Badge></td>
                                    <td><Badge style={{ ...badgeStyle }} className='m-2' bg="secondary">{licence.productionCode}</Badge></td>
                                    <td><Badge style={{ ...badgeStyle }} className='m-2' bg='secondary'>{licence.appVersion}</Badge></td>
                                    <td><Badge className='m-2' bg='secondary'>{licence._id}</Badge></td>
                                    <td><Badge onClick={() => goMachineDetail(user._id)} style={{ ...badgeStyle }} className='m-2' bg='secondary'>{licence.maximumNumberOfMachines} Adet</Badge></td>
                                    <td><Badge className='m-2'>{formatTarih(Number(licence.updatedAt))}</Badge></td>
                                    <td><Badge className="m-2" bg={`${badgeClassName}`}>{formatTarih(Number(licence.expirationDate))}</Badge></td>
                                    <td><Badge style={{ ...badgeStyle }} className='m-2' bg={licence.featuresF1 === true ? 'danger' : 'success'}>{licence.featuresF1 === true ? 'Demo' : 'Lisans'}</Badge></td>
                                    <td><Badge className='m-2' bg="danger" onClick={() => deleteLicenceItem(licence._id)}><BsTrash /></Badge></td>
                                    <td><Badge className='m-2' bg="warning" onClick={() => editLicenceItem(licence._id)}><BsPencil /></Badge></td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Col>

            <Col md={2} lg={1} xs={6} style={{ display: 'flex', flexDirection: 'column', padding: 5, alignItems: 'center', alignContent: 'center' }}>
                <Button className='m-1' variant="success" size="sm" style={{ width: '100%' }} onClick={navigationHandle}>Yeni Lisans</Button>
                <Button className='m-1' variant="danger" size="sm" style={{ width: '100%' }} onClick={() => deleteCostumerItem(user._id)}>Sil</Button>
            </Col>
        </Col>

    );
};


export default UserListItem;