import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import { REGISTER } from '../../../Graphql/Mutations/Auth.js'
import { useMutation } from '@apollo/client';
import { checkToken } from '../../../services/Auth'
import { useNavigate } from "react-router-dom";
import { isEmailValid } from "../../../Helper/EmailStringChecker.js";


function RegisterPage() {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [error, setError] = useState("");



    const [Register, { loading }] = useMutation(REGISTER)

    const __register = () => {
        if (!isEmailValid(email)) {
            setError('Lütfen geçerli bir email giriniz!')
            return
        }
        if (password !== password2 || password.length <= 5) {
            setError('Parola en az 6 karekter içermeli ve eşit olmalı!')
            return
        }
        if (username != null & company != null & email != null) {
            Register({
                variables: {
                    username: username,
                    email: email,
                    company: company,
                    password: password
                }, onCompleted: ({ register }) => {
                    const { token } = register
                    if (token !== undefined || token != null) {
                        localStorage.setItem('token', `Bearer ${token}`);
                        localStorage.setItem('token', `Bearer ${token}`);
                        checkToken()
                        navigate('/costumer')
                    }
                }
            }).catch(err => {
                setError(err.message);
                localStorage.setItem('token', "");
            })
        } else {
            setError("Formda hatalı bilgiler var!")
        }
    }

    useEffect(() => {

        if (checkToken()) {
            navigate('/costumer')
        }
    })

    return (
        <Container>
            <Row className="vh-100 d-flex justify-content-center align-items-center">
                <Col md={8} lg={6} xs={12}>
                    <div className="border border-3 border-primary"></div>
                    <Card className="shadow">
                        <Card.Body>
                            <div className="mb-3 mt-md-4">
                                <h2 className="fw-bold mb-2 text-uppercase ">KAYDOL</h2>
                                <div className="mb-3">
                                    <Form >
                                        {error && <Alert variant="danger">{error}</Alert>}

                                        <Form.Group className="mb-3" controlId="formBasicName">
                                            <Form.Label className="text-center">
                                                Kullanıcı Adı
                                            </Form.Label>
                                            <Form.Control
                                                type="username"
                                                placeholder="Kullanıcı Adı"
                                                value={username}
                                                onChange={(event) => { setUsername(event.target.value.trim()) }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCompanyname">
                                            <Form.Label className="text-center">
                                                Firma Adı
                                            </Form.Label>
                                            <Form.Control
                                                type="companyname"
                                                placeholder="Firma Adı"
                                                value={company}
                                                onChange={(event) => { setCompany(event.target.value.trim()) }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label className="text-center">
                                                E-Posta
                                            </Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder="e-posta adresi"
                                                value={email}
                                                onChange={(event) => { setEmail(event.target.value.toLowerCase().trim()); }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Parola</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Parola"
                                                value={password}
                                                onChange={(event) => { setPassword(event.target.value) }}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="formBasicPassword2">
                                            <Form.Label>Parola Tekrar</Form.Label>
                                            <Form.Control
                                                type="password"
                                                placeholder="Parola Tekrar"
                                                value={password2}
                                                onChange={(event) => { setPassword2(event.target.value) }}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                        </Form.Group>
                                        <div className="d-grid">
                                            <Button variant="primary" onClick={(e) => __register(e)} disabled={loading ? true : false}>
                                                Kaydol
                                            </Button>
                                        </div>
                                    </Form>
                                    <div className="mt-3">
                                        <p className="mb-0  text-center">
                                            Hesabım Var{" "}
                                            <a href="login" className="text-primary fw-bold">
                                                Giriş
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default RegisterPage;