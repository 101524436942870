import React, { useState } from 'react';
import { Container, Row, Col, Button, ListGroup, Badge, Form, Card, FormControl, FormLabel, DropdownButton, Dropdown } from 'react-bootstrap';
import { DELETE_LICENCE, ADD_LICENCE } from '../../../Graphql/Mutations/AdminUser';
import { useMutation, useQuery } from '@apollo/client';
import { USER_PROFILE } from '../../../Graphql/Queries/Costumer';
import { useNavigate } from "react-router-dom";

import { formatTarih } from '../../../Helper/TimeHelper'
import { BsTrash, BsPencil } from 'react-icons/bs'



const LicenceAdd = (costumer) => {
    const navigate = useNavigate();

    const [productionCode, setProductionCode] = useState("")
    const [appVersion, setappVersion] = useState('')
    const [maximumNumberOfMachines, setmaximumNumberOfMachines] = useState(0)
    const [expirationDate, setExpirationDate] = useState(new Date())
    const [featuresF1, setFeaturesF1] = useState(true)
    const [featuresF2, setFeaturesF2] = useState(true)
    const [featuresF3, setFeaturesF3] = useState(true)
    const [featuresF4, setFeaturesF4] = useState(true)
    const [featuresF5, setFeaturesF5] = useState(true)
    const [notes, setNotes] = useState("")

    const currentCostumerId = localStorage.getItem("currentCostumerId")
    const { error, data, refetch } = useQuery(USER_PROFILE, { variables: { userId: currentCostumerId } });
    const [AddLicence, {loading}] = useMutation(ADD_LICENCE)


    if (!data) {
        return (
            <div>
                HATA
            </div>
        )
    }

    const addNewLicence = () => {
        let expDate =(new Date(expirationDate).valueOf().toString())
        if (!currentCostumerId && !productionCode && !appVersion && maximumNumberOfMachines < 1) {
            alert("Form değerlri hatalı")
            return
        } else if (expirationDate <= new Date()) {
            alert("Son kullanma tarihi bugünün tarihinden en az bir gün ileride olmalı!")
            return
        }
        AddLicence({
            variables: {
                costumerId: currentCostumerId, productionCode, appVersion, maximumNumberOfMachines, expirationDate:expDate, featuresF1, featuresF2, featuresF3, featuresF4, featuresF5, notes
            },
            onCompleted: ({addLicence}) => {
                alert(addLicence.message)
                navigate('/admin')
            },
            onError:(error)=>{ 
                alert(`Bir hata alındı! ${error}`)
            }
        })
    }

    const { userProfile } = data
    return (
        <Row className="vh-100 d-flex justify-content-center" style={{ marginTop: '10%' }}>
            <Col md={8} lg={10} xs={12}>
                <h4 style={{ backgroundColor: '#EAEAEA', borderRadius: 5, padding: 10, alignItems: 'center', alignContent: 'center' }}>Yeni Lisans Ekle</h4>
                <Form>
                    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}>Müşteri Email</Form.Label>
                            <Form.Control type="text" disabled placeholder={userProfile.costumer.email} value={userProfile.costumer.email} />
                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}>Müşteri Kullanıcı Adı</Form.Label>
                            <Form.Control type="text" disabled value={userProfile.costumer.username} />

                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}>Müşteri Şirket Adı</Form.Label>
                            <Form.Control type="text" disabled value={userProfile.costumer.companyName} />
                        </Col>
                    </Row>


                    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans Adı</Form.Label>
                            <Form.Control type="text" placeholder='Etex' value={productionCode} onChange={(event) => setProductionCode(event.target.value)} />
                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans Versiyon</Form.Label>
                            <Form.Control type="text" placeholder='3.5.1' value={appVersion} onChange={(event) => setappVersion(event.target.value)} />
                        </Col>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans Adet</Form.Label>
                            <Form.Control type="number" placeholder='1' value={maximumNumberOfMachines} onChange={(event) => setmaximumNumberOfMachines(parseInt(event.target.value))} />
                        </Col>
                    </Row>

                    <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Col md={4} lg={4} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Son Kullanma Tarihi</Form.Label>
                            <Form.Control type="date" name="datepic" placeholder="DateRange" onChange={(e) =>{setExpirationDate(new Date(e.target.value))}} value={formatTarih(expirationDate.valueOf())} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F1(Demo)</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF1} onChange={(event) => setFeaturesF1(event.target.checked)} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F2</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF2} onChange={(event) => setFeaturesF2(event.target.checked)} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F3</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF3} onChange={(event) => setFeaturesF3(event.target.checked)} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F4</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF4} onChange={(event) => setFeaturesF4(event.target.checked)} />
                        </Col>
                        <Col md={1} lg={1} xs={2}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }}  >F5</Form.Label>
                            <Form.Check type="checkbox" checked={featuresF5} onChange={(event) => setFeaturesF5(event.target.checked)} />
                        </Col>
                        <Col md={12} lg={12} xs={12}>
                            <Form.Label style={{ color: '#ffffff', marginTop: '8px' }} >Lisans Notları</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder='Lisans notlari' value={notes} onChange={(event) => setNotes(event.target.value)} />
                        </Col>
                    </Row>


                    <Button variant="success" style={{ marginTop: '16px' }} onClick={addNewLicence} disabled={loading}>
                        YENİ LİSANS EKLE
                    </Button>
                </Form>
            </Col>
        </Row>

    );
};


export default LicenceAdd;