import { gql } from '@apollo/client'


 
export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;



export const REGISTER = gql`
    mutation Register($username:String!, $email: String!,$company:String!, $password:String!){
        register(username: $username, email: $email, company:$company, password: $password){
            token   
        }
    }
`
 

export const FORGOT_PASSWORD =gql`
    mutation ForgatPasswordEmail($email:String!){
        forgatPasswordEmail(email:$email){
            message
        }
    }
`


export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($userId:String!, $password: String!, $newPassword: String!){
        changePassword(userId:$userId, password: $password,newPassword: $newPassword){
            message
        }
    }
`