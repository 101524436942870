import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import etex from "../../Assets/Projects/etex.png";
import netex from "../../Assets/Projects/netex.png";
import kisakonsolkiris from "../../Assets/Projects/kisaKonsolKiris.png";
import kolonTiplendirme from "../../Assets/Projects/kolonTiplendirme.png";
import basitegilme from "../../Assets/Projects/basitegilme.png";
import tekiltemel from "../../Assets/Projects/tekiltemel.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
        <strong className="purple">Yapısal Tasarım</strong> ve <strong className="purple">Uygulama Projeleri </strong> İçin Araçlar 
        </h1>
        <p style={{ color: "white" }}>
          Statik projelendirme yapan bir firmada olması gereken bir çok araç aşağıdaki yazılımlarda mevcut.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={etex}
              isBlog={false}
              title="Etex"
              description="Etabs API kullanarak TDBY2018 ve Ts500 gibi yönetmeliklere uygun çeşitli kontroller ve raporlamalar yapabilen bir Windows masaüstü uygulaması. Aktif olarak projelendirme için kullanılmakta."
              ghLink="costumer"
              demoLink="etex"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={netex}
              isBlog={false}
              title="Netex"
              description="Performans analizinde sınır değerlerin hesaplanması, mafsal tanımlanması, sınır değerlerin ilgili kesitin ilgili parametrelerine girilmesi ve sonuç verilerini raporlama için kullanılan Etabs API ile çalışan raporlama ve veri işleme yazılımı."
              ghLink="costumer"
              demoLink="netex"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Kolon Uygulama"
              description="AutoCAD API ile çalışan kolon detay çizimlerini hazırlayan bir yazılım. Otomatik kolon tiplendirme, uygulama paftası oluşturma ve metraj alma gibi özellikleri mevcut. 
              Ayrıca Revit ile entegre bir şekilde çalışabilmekte. Geliştirilme aşamasında..."
              ghLink="costumer"
              demoLink="betonarmedonati"              
            />
            
          </Col> 
        </Row>


        <h1 className="project-heading">
          <strong className="purple">Açık Kaynak Kodlu </strong> Araçlar 
        </h1>
        <p style={{ color: "white" }}>
          Projelendirmede kullanılan bazı yapısal hesaplamalar ve uygulama çizimlerinin oluşturulmasında kullanıcılara kolaylık sağlayan araçları bu alandan indirebilirsiniz.
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tekiltemel}
              isBlog={false}
              title="Tekil Temel Hesabı"
              description="Eksenel kuvvet ve moment etkisi altında bulunan tekil temellerin boyutlandırılması ve donatılandırılması amacıyla geliştirilmiş bir tasarım aracıdır."
              ghLink="https://1drv.ms/u/s!Aj4rScQWD4KgiZg0ZM4uUZAcTwrArA?e=WRZv8I"
              demoLink="https://github.com/rufaidemir"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kisakonsolkiris}
              isBlog={false}
              title="Kısa Konsol Hesabı"
              description="Kısa konsol kirişlerin TS500'e göre kesit boyutlarını ve donatı alanlarını hesaplar. Kısa konsol kiriş parametrelerini girilerek çekme, sürtünme ve kesme donatılarının hesaplanamsı, kesit kotnrolü gibi özellikler sunar."
              ghLink="https://1drv.ms/u/s!Aj4rScQWD4KgiZdd2M8ad6D43H1RgA?e=y7oE4Q"
              demoLink="https://github.com/rufaidemir/KisaKonsolKiris"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={basitegilme}
              isBlog={false}
              title="Basit Eğilme Hesabı"
              description="Döşeme, betonarme perde gibi elemanların eğilme donatılarının kesit ve malzeme bilgileri kullanarak hesaplayan ve kesit kontrolü yapan bir araçtır."
              ghLink="https://1drv.ms/u/s!Aj4rScQWD4KgiZdu0WvQKOHeNQV3cA?e=Yz56X9"
              demoLink="https://github.com/rufaidemir"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kolonTiplendirme}
              isBlog={false}
              title="Kolon Tiplendirme"
              description="Kolon parametrelerinin yer aldığı excel dosyasında kolon kesiti, donatı adedi gibi kullanıcının belirlediği parametrelere göre kolonlarda tiplendirme yapar. Tiplendirilen kolonlar uygulama çizimlerinde bir açılımla birden fazla kolon detayı gösterme kolaylığını sağlar."
              ghLink="https://1drv.ms/u/s!Aj4rScQWD4KgiZZi8vy0odvFVsOGPg?e=v0wgwh"
              demoLink="https://github.com/rufaidemir"
            />
          </Col>

        </Row>






      </Container>
    </Container>
  );
}

export default Projects;
