import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
               <span className="purple"> HAKKIMDA </span> KISA BİR BİLGİLENDİRME
            </h1>
            <p className="home-about-body">
            Merhaba ben Rufai. Yazılım, BIM ve yapısal tasarım ile ilgileniyorum.
              <br />
              <br />Bu sistemleri ortak bir sekilde kullanmayı amaçlayan birçok yazılım geliştirdim ve devam etmekteyim.
              <i>
                <b className="purple"> Python, C# ve Javascript </b>bu sistemleri kurabilmek için kullandığım bazı <b className="purple">teknolojiler.</b>
              </i>
              <br />
              <br />
              <i>
                <b className="purple">Etabs , Revit </b> ve bazı diger BIM
                yazılımlarını birbiri ile iletişim kuracak şekilde kullanmayı seviyorum{" "}
                <b className="purple">
                  CSI API, Revit API, AutoCAD API 
                </b> konularında oldukça fazla deneyimim oldu.
              </i>
              <p>Bu konuda geliştirdiğim yazılımları şu linkten inceleyip dilerseniz <b className="purple"><a className="purple" href="project">deneyebilirsiniz.</a></b></p>
              <br />
              <br /> 
              BIM projelerinin yapısal tasarımla entegrasyonu konusunda
              çalışmalar yürüttüm ve hala geliştirerek devam etmekteyim. Yapısal
              Tasarım, BIM ve yazılımı bir arada kullanarak otomatize edilmiş ve
              kontrol edilebilir sistemler gelistirmeye çalışıyorum.
               
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" style={{borderRadius:'2rem', }}/>
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>İLETİŞİM</h1>
            <p>
              Bana <span className="purple">Sosyal Medya </span> Hesaplarımdan Ulaşabilirsiniz
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/rufaidemir"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/rufaidemir"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/rufai-demir/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/rfdmr"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
