import { gql } from '@apollo/client'
 

export const GET_EDIT_LICENCE = gql`
   query getEditLicence($licenceId: String!){
        getEditLicence(licenceId: $licenceId) {
                costumer {
                    _id
                    company
                    email
                    username
                    }
                _id
                appVersion
                expirationDate
                featuresF1
                maximumNumberOfMachines
                productionCode
                updatedAt
                notes
                featuresF1
                featuresF2
                featuresF3
                featuresF4
                featuresF5
                machines {
                    _id
                    architecture
                    hostname
                    ipAddress
                    ipAddressOut
                    isActiveForUsing
                    isBlocked
                    platform
                    platformRelease
                    platformVersion
                    macAddress
                    ram
                    processor
                }
        }
    }
    
`


  