import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import { CHANGE_PASSWORD } from '../../../Graphql/Mutations/Auth'
import { useMutation } from '@apollo/client'
import { useNavigate } from "react-router-dom";


function ChangePasswordPage() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [ChangePassword] = useMutation(CHANGE_PASSWORD);

  const handleSubmit = (event) => {
    event.preventDefault();
    if ((newPassword == null) | (newPassword === undefined)) {
      setErrorMessage("E-posta ve parola zorunlu alanlardır.");
      return;
    }
    
    if (newPassword.length<5) {
      setErrorMessage("Yeni parola en az 6 karakter olmalı!");
      return;
    }
    
    
    if (newPassword !== newPassword2) {
      setErrorMessage("Yeni parola değerleri eşit değil!");
      return;
    }
    
    try {
      const userId = localStorage.getItem('currentUserId');
      console.log({ userId, password, newPassword2 });
      ChangePassword({
        variables: { userId, password, newPassword },
        onCompleted: ({changePassword}) => { 
          alert(changePassword.message)
          navigate('/login')
        },
        onError: (e) => {
          setErrorMessage(e.message)
        }
      });
    } catch (e) {
      setErrorMessage(e.message);
    }
  };
 

  return (
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <div className="border border-3 border-primary"></div>
          <Card className="shadow">
            <Card.Body>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase ">PAROLA DEĞİŞTİR</h2>
                <div className="mb-3">
                  <Form>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

                    <Form.Group className="mb-3" controlId="formBasicPassword" >
                      <Form.Label>Mevcut Parola</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Mevcut Parola"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword1" >
                      <Form.Label>Yeni Parola</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Yeni Parola"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword2" >
                      <Form.Label>Yeni Parola Tekrar</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Yeni Parola Tekrar"
                        value={newPassword2}
                        onChange={(e) => setNewPassword2(e.target.value)}
                      />
                    </Form.Group>


                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox"
                    >
                      <p className="small">
                        <a className="text-primary" href="/forgotpassword">
                          Şifremi Unuttum?
                        </a>
                      </p>
                    </Form.Group>
                    <div className="d-grid">
                      <Button variant="primary" onClick={handleSubmit}>
                        DEĞİŞTİR
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePasswordPage;