import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';


function ProuctCard({appName,appContent,imagePath, appPrice, appHref}) {
    const nav = useNavigate()
  return (
    <Card style={{ width: '30%' }}>
      <Card.Img variant="top" src={imagePath} onClick={()=>{nav(`/${appName}`)}}/>
      <Card.Body>
        <Card.Title>{appName}</Card.Title>
        <Card.Text>
            {appContent}
        </Card.Text>
        <Card.Footer className='h2'>{appPrice}</Card.Footer>

        <Button  style={{marginLeft:5, marginRight:5}} href={appHref} variant="primary">Satın Alma Talebi</Button>
        <Button   style={{marginLeft:5, marginRight:5}}  href='mailto:rufai.demir@yaani.com?subject=Demo Talebi"' variant="secondary">Demo Talebi</Button>
      </Card.Body>
    </Card>
  );
}

export default ProuctCard;