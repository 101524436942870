export function formatTarih(timestamp) {

    timestamp = parseInt(timestamp,10)
    const date = new Date(timestamp); 
  
    const gun = String(date.getDate()).padStart(2, '0');
    const ay = String(date.getMonth() + 1).padStart(2, '0');
    const yil = date.getFullYear();
 
  
    return `${yil}-${ay}-${gun}`;
  }