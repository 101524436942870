import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';



let uri = 'http://localhost:2500/graphql';
if (process.env.NODE_ENV === 'production') {
  uri='https://etexapiguard.onrender.com/graphql'
}

// console.log("-----------ENDPOINT-------->",uri);

const httpLink = createHttpLink({
  uri: uri,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Credentials': true,
  }, 
});

const link = ApolloLink.from([
  httpLink,
]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  
  
});


export { client }
