import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap"; 
import { AiOutlineCheckCircle, AiFillRightCircle } from 'react-icons/ai'
 

function Netex() {
  return (
    <section>
      
      <Container fluid className="home-sectison" id="homes">
        <Container className="home-content">
          <Row>
            <Col md={7}>
              <h1 style={{ paddingBottom: 15 }} >
                NETEX{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻 Etabs - Excel Araçları - Şekildeğiştirmeye Göre Tasarım 
                </span>
              </h1> 
              <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?list=PLDJRbGrtJPAxlAGZ50oMr7xZUVVEEvRbd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <p>
              <span className="purple">Şekil değiştirmeye göre tasarım</span> için geliştirilmiş <span className="purple">Etabs API</span> kullanarak TBDY2018 yönetmeliğine uygun çeşitli kontroller ve raporlamalar yapan bir yazılım.
              <br/> Diğer yönetmeliklere excel formatları değiştirilerek uyum sağlayabilir.
              <br/>

              Netex betonarme hesaplamalar için <span className="purple">CSI Etabs</span> yazılımı ve <span className="purple">MS Excel</span> ile entegre çalışır.<br/>
              Otomatik olarak <span className="purple">çubuk (frame)</span> elemanlarda her kesit için ayrı ayrı <span className="purple">plastik mafsal(hinge)</span> tanımlama bu mafsalları ilgili elemanlara <span className="purple">atama</span>, mafsal sınır değerlerini hesaplama ve modelde ilgili parametrelere bu hesaplanan sınır değerleri<span className="purple">yazma</span>  gibi özellikleri mevcut. <br/>
              <br/> <span className="purple">Perde (Wall)</span> elemenları için yönetmelikçe belirtilen sınır değerleri hesaplar ve rapor oluşturur. Bütün bu işlemleri bir excel dosyasında yapar. Bu şekilde kullanıcının <span className="purple">değerlere, formüllere ve formata müdahale edebilmesini</span> sağlar.
              <br/>
              <br/>
              Modelleme ve Etabs’te çözülen <span className="purple">Nonlinear</span> modellerin sonuçlarını <span className="purple">PDF</span> ve <span className="purple">Word</span> formatında rapor üretir.
              <br/> <br/>
              <AiFillRightCircle/> Çubuk Eleman Dönme Sınır Değerleri,<br/>
              <AiFillRightCircle/> Perde Elemanları Şekil Değiştime Sınır Değerleri Raporu,<br/>
              <AiFillRightCircle/> Perde Elemanların Kesme Kuvveti Raporu,<br/>
              <AiFillRightCircle/> Yapının Göreli Kat Öteleme Raporu ve <br/>
              <AiFillRightCircle/> Kolon Eksenel Kapasite Raporu'nu 
               <br/><span className="purple">Word ya da PDF</span> formatında oluşturur.<br/><br/>
              En önemli ve öne çıkan özelliklerinden biri veri işleme,<span className="purple"> büyük veri(Big Data)</span> vesaire gibi alanlarda kullanılan  teknolojileri kullanarak çok hızlı bir şekilde verileri analiz edip sonuçları almasıdır.  
              <br/>Aşağıdaki butona tıklayarak deneyebilirsiniz. 
              </p>
              <br />
            <Button href="/costumer">NETEX İNDİR</Button>
            </Col>
            <Col md={12} style={{ paddingBottom: 20 }}>
              <h3>NETEX'TE BULUNAN ÖZELLİKLER</h3>
                <br/><AiOutlineCheckCircle /><a href='#01' style={{textDecoration: 'none', color:'#ffffff'}} >01 - Modelleme | Çubuk Eleman(Frame) Sınır Değerleri Hesaplama </a>
                <br/><AiOutlineCheckCircle /><a href='#01' style={{textDecoration: 'none', color:'#ffffff'}} >02 - Modelleme | Çubuk Eleman(Frame) Etkin Kesit Rijitlikleri Hesaplama</a>
                <br/><AiOutlineCheckCircle /><a href='#01' style={{textDecoration: 'none', color:'#ffffff'}} >03 - Modelleme | Çubuk Eleman(Frame) Mafsal Oluşturma ve Atama</a>
                <br/><AiOutlineCheckCircle /><a href='#01' style={{textDecoration: 'none', color:'#ffffff'}} >04 - Modelleme | Perde Eleman(Wall) Şekildeğiştirme Sınırları Hesaplama</a>
                <br/><AiOutlineCheckCircle /><a href='#02' style={{textDecoration: 'none', color:'#ffffff'}} >05 - Raporlama | Göreli Kat Öteleme Kontrolü</a>
                <br/><AiOutlineCheckCircle /><a href='#02' style={{textDecoration: 'none', color:'#ffffff'}} >06 - Raporlama | Çubuk Eleman(Frame) Plastik Dönme Raporu</a>
                <br/><AiOutlineCheckCircle /><a href='#06' style={{textDecoration: 'none', color:'#ffffff'}} >07 - Raporlama | Perde Eleman(Wall) Şekildeğiştirme Raporu</a>
                <br/><AiOutlineCheckCircle /><a href='#06' style={{textDecoration: 'none', color:'#ffffff'}} >08 - Raporlama | Perde Eleman(Wall) Kesme Raporu</a>
                <br/><AiOutlineCheckCircle /><a href='#06' style={{textDecoration: 'none', color:'#ffffff'}} >09 - Raporlama | Kolon Eksenel Kapasite Kontrol Raporu</a> 
                <br/>
        </Col>
          </Row>
          <Row>
          <div className="etex__tools">
        <h1>Netex Araçları</h1>
         <EtexTool id="01" title="Netex Modelleme" video_uri="https://www.youtube.com/embed/mJNyxbTtVLE"  properties={[ " Çubuk Eleman(Frame) Sınır Değerleri Hesaplama "," Çubuk Eleman(Frame) Etkin Kesit Rijitlikleri Hesaplama "," Çubuk Eleman(Frame) Mafsal Oluşturma ve Atama "," Perde Eleman(Wall) Şekildeğiştirme Sınırları Hesaplama"]}/>
         {/*
        <EtexTool id="02" title="Çubuk Eleman(Frame) Etkin Kesit Rijitlikleri Hesaplama" video_uri="https://www.youtube.com/embed/mJNyxbTtVLE"   properties={[ "Kolon ve Kiriş elemanlar için etkin kesit rijitliklerinin hesaplanması", "Değiştirilebilir excel fomatında veri", "Grafik ile raporlama", "Hızlı sonuç okuyabilme", "Word ve PDF formatında rapor oluşturma"]}/>
        <EtexTool id="03" title="Çubuk Eleman(Frame) Mafsal Oluşturma ve Atama" video_uri="https://www.youtube.com/embed/2TV4eLEKf6w" properties={[ "Kolon ve Kiriş elemanlar için mafsal oluşturma", "Plastik Mafsal(Hinge) sınır değerlerini Etabs'e işleyebilme", "Grafik ile raporlama", "Hızlı sonuç okuyabilme", "Word ve PDF formatında rapor oluşturma"]}/>
        <EtexTool id="04" title="Perde Eleman(Wall) Şekildeğiştirme Sınırları Hesaplama" video_uri="https://www.youtube.com/embed/0XfHUiibt0g" properties={[ "Perde elemanlar için şekildeğiştirme sınırlarının hesaplanması", "Değiştirilebilir excel fomatında veri", "Grafik ile raporlama", "Hızlı sonuç okuyabilme", "Word ve PDF formatında rapor oluşturma"]}/>
        <EtexTool id="05" title="Göreli Kat Öteleme Kontrolü" video_uri="https://www.youtube.com/embed/lAvtfxSilFI"  properties={[ "Kat ötelemelerinin şeçilen kombinasyonlara göre kontrolü", "Grafik ile raporlama", "Hızlı sonuç okuyabilme", "Word ve PDF formatında rapor oluşturma"]}/>
        <EtexTool id="06" title="Raporlar" video_uri="https://www.youtube.com/embed/ao76k-IdbHc" properties={[ "Kolon-Kiriş(Frame) Eleman Raporu","Perde (Wall) Eleman Şekildeğiştirme Raporu", "Perde(Wall) Eleman Kesme Kuvveti Raporu", "Göreli Kat Öteleme Kontrol Raporu", "Kolon Eksenel Kapasite Kontrol Raporu","PDF ve Word formatında çıktı alabilme"]}/> */}
      </div>
          </Row>
        </Container>
      </Container> 
       
    </section>
  );
}


function EtexTool(props) {

  return <div className="mt-4" style={{ backgroundColor:'#3D3C40', borderRadius: 5, padding: 6, transition: 'transform 0.3s ease-in-out'  }}>
    <h2 className="purple" href={props.id}>{props.id} - {props.title}</h2>
    <section className="tool_content" id={props.id}>
      <Row className="flex-row m-5" >
        <Col md={4} lg={6} xs={12}>
          <iframe width="100%" height="100%" src={props.video_uri} title={props.title} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Col>
        <Col md={4} lg={6} xs={12} >
          {props.properties.map(item => {
            return <li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'baseline' }}><AiOutlineCheckCircle size={20} className="purple" /><p >{item}</p></li>
          })}        </Col>
      </Row>
    </section>
  </div>
}
export default Netex;
