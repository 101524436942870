import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import { FORGOT_PASSWORD } from '../../../Graphql/Mutations/Auth'
import { useMutation } from '@apollo/client'
import { useNavigate } from "react-router-dom";
import { isEmailValid } from "../../../Helper/EmailStringChecker.js";


function ForgatPasswordPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [ForgatPassword] = useMutation(FORGOT_PASSWORD);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isEmailValid(email)) {
      setErrorMessage('Lütfen geçerli bir email giriniz!')
      return
    }
    if ((email == null) | (email === undefined)) {
      setErrorMessage("E-posta geçerli değil.");
      return;
    }
     
    try {
      ForgatPassword({
        variables: { email },
        onCompleted: ({forgatPasswordEmail}) => { 
          alert(forgatPasswordEmail.message)
          navigate('/login')
        },
        onError: (e) => {
          setErrorMessage(e.message)
        }
      });
    } catch (e) {
      setErrorMessage(e.message);
    }
  };
  
  return (
    <Container>
      <Row className="vh-100 d-flex justify-content-center align-items-center">
        <Col md={8} lg={6} xs={12}>
          <div className="border border-3 border-primary"></div>
          <Card className="shadow">
            <Card.Body>
              <div className="mb-3 mt-md-4">
                <h2 className="fw-bold mb-2 text-uppercase ">PAROLAMI UNUTTUM</h2>
                <div className="mb-3">
                  <Form>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form.Group className="mb-3" controlId="formBasicPassword" >
                      <Form.Label>e-mail</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="e-mail"
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase().trim())}
                      />
                    </Form.Group>   
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox"
                    >
                      <p className="small">
                        <a className="text-primary" href="/login">
                          Giriş
                        </a>
                      </p>
                    </Form.Group>
                    <div className="d-grid">
                      <Button variant="primary" onClick={handleSubmit}>
                        ŞİFREMİ MAİL GÖNDER
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgatPasswordPage;