export function formatString(str) {
    const segmentLength = 4;
    const segments = [];
  
    for (let i = 0; i < str.length; i += segmentLength) {
      segments.push(str.substr(i, segmentLength));
    }
  
    const formattedString = segments.join('-');
    return formattedString;
  }